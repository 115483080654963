import ng from 'angular';
import { AcpBrandingClient, AcpDeviceService, Brand } from 'core';
import acpMobileAppUpgradeTemplate from './templates/acp-mobile-app-upgrade-dialog.html';

export class AcpMobileAppUpgradeDialog {
  branding: Brand;

  constructor(
    private $mdDialog: ng.material.IDialogService,
    private acpBrandingClient: AcpBrandingClient,
    private $window: ng.IWindowService,
    private nsPermissions: nsUtils.NsPermissionsService,
    private acpDeviceService: AcpDeviceService
  ) {
    'ngInject';

    this.branding = this.acpBrandingClient.get();
  }

  get isAndroid() {
    return this.acpDeviceService.isAndroid();
  }

  get isIos() {
    return this.acpDeviceService.isIos();
  }

  async isUpgradeRequired(): Promise<boolean> {
    if (!this.acpDeviceService.isCordova()) {
      return false;
    }

    return await this.nsPermissions.requestPermission(
      'isMobileAppUpgradeRequire'
    );
  }

  goBack() {
    this.$window.history.back();
  }

  show() {
    const vm = this;
    const controllerPopUp = /*@ngInject*/ function ($scope) {
      $scope.vm = vm;
    };
    this.$mdDialog.show({
      parent: ng.element(document.body),
      template: acpMobileAppUpgradeTemplate,
      controller: controllerPopUp
    });
  }
}
