import ng from 'angular';
import acpCore from 'core';
import { AcpMobileAppUpgradeDialog } from './acp-mobile-app-upgrade-dialog-service';
import mobileAppUpgradeDialogContent from './mobile-app-upgrade-dialog.yml';
import './styles/${theme}/core.scss';

/**
 * @acpdoc component
 * @ngdoc module
 * @name acp.component.mobile-app-upgrade-dialog
 *
 * @requires acp.core
 *
 * @description
 *
 * @example
 * ```html
 * <acp-mobile-app-upgrade-dialog></acp-mobile-app-upgrade-dialog>
 * ```
 *
 * ```js
 * angular.module('acp.component.add-contact.example', [
 *   require('acp.component.mobile-app-upgrade-dialog').name
 * ]);
 * ```
 */
const acpComponentMobileAppUpgradeDialog = ng
  .module('acp.component.mobile-app-upgrade-dialog', [acpCore.name])
  .service('acpMobileAppUpgradeDialog', AcpMobileAppUpgradeDialog)
  .run(
    /*@ngInject*/ function (contentSectionCache) {
      contentSectionCache.put(
        'components/mobile-app-upgrade-dialog',
        mobileAppUpgradeDialogContent
      );
    }
  );

export { acpComponentMobileAppUpgradeDialog, AcpMobileAppUpgradeDialog };
